<script>
  import {
    required,
    email,
    helpers
  } from "@vuelidate/validators";
  import appConfig from "../../../app.config";
  import Swal from "sweetalert2";
import axios from 'axios';
  export default {
    props: ['hash', 'code'],
    page: {
      title: "Session",
      meta: [{
        name: "description",
        content: appConfig.description,
      }, ],
    },
    data() {
      return {
        
        phone: "",
        password: "",
        auth:false,
        submitted: false,
        authError: null,
        tryingToLogIn: false,
        isAuthError: false,
      };
    },
    validations: {
      phone: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Please enter valid email", email),
      },
      password: {
        required: helpers.withMessage("Password is required", required),
      },
    },
    computed: {

    },
    created() {
      this.checksession();
    },
    methods: {
 
     
     async loginsession() {
        this.tryingToLogIn = true;

    await axios.post(process.env.VUE_APP_MAIN_URL + "/auth/session/login", {phone: this.phone, password: this.password, code: this.code, hash: this.hash}).catch((error) => {

            this.tryingToLogIn = false;

            if (error.response.status == 420) {
               Swal.fire("Error!", error.response.data.data.message, "error");
            }else {
                  Swal.fire("Error!", "Please make sure you have active internet or fields are correct", "error");
            }

               
                throw error;

            }).then((response) => {
                 this.tryingToLogIn = false;
                 if (response.data.status == true) {

                    this.auth = true;
                    
                }
                //  this.$router.push({name: "transaction", params: { hash: response.data.data.data.hash}});

            })

      },


         async checksession() {


    await axios.post(process.env.VUE_APP_MAIN_URL + "/auth/session/check", {code: this.code, hash: this.hash}).catch((error) => {

          

          console.log(error)

            }).then((response) => {


                if (response.data.status == true) {

                    this.auth = true;
                    
                }


                
         
                 console.log(response)
                //  this.$router.push({name: "transaction", params: { hash: response.data.data.data.hash}});

            })

      },



       
      }
    
  };
</script>

<template>
  <div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8
            1440,40L1440 140L0 140z"></path>
        </svg>
      </div>
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <router-link to="/" class="d-inline-block auth-logo">
                  <img src="@/assets/images/logo-light.png" alt="" height="40" />
                </router-link>
              </div>
              <p class="mt-3 fs-15 fw-medium">
                Best bill payment in nigeria
              </p>
            </div>
          </div>
        </div>
        <!-- end row -->

        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4">
                   <div class="card mt-4" v-if="auth">
              <div class="card-body p-4 text-center">
                <div class="avatar-lg mx-auto mt-2">
                  <div class="
                      avatar-title
                      bg-light
                      text-success
                      display-3
                      rounded-circle
                    ">
                    <i class="ri-checkbox-circle-fill"></i>
                  </div>
                </div>
                <div class="mt-4 pt-2">
                  <h4>Gobot is Online !</h4>
                  <p class="text-muted mx-4">
                    You can now perform transaction on whatsapp.
                  </p>
                  <div class="mt-4">
                   
                  </div>
                </div>
              </div>
              <!-- end card body -->
            </div>
              <div class="card-body p-4" v-else>
                <div class="text-center mt-2">
                  <h5 class="text-primary">Welcome Back !</h5>
                  <p class="text-muted">Sign in to continue to Gobot.</p>
                </div>
                <div class="p-2 mt-4">
                  <b-alert v-model="authError" variant="danger" class="mt-3" dismissible>{{ authError }}</b-alert>

                  <div>

                  </div>

                  <form @submit.prevent="tryToLogIn">
                    <div class="mb-3">
                      <label for="phone" class="form-label">Phone Number</label>
                      <input type="text" class="form-control" id="phone" placeholder="Enter phone number" v-model="phone" />
                      <div class="invalid-feedback">
                        <span></span>
                      </div>
                    </div>

                    <div class="mb-3">
                      <div class="float-end">
                        <router-link to="/forgot-password" class="text-muted">Forgot
                          password?</router-link>
                      </div>
                      <label class="form-label" for="password-input">Password</label>
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input type="password" v-model="password" class="form-control pe-5" placeholder="Enter password"
                          id="password-input" />
                        <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                          type="button" id="password-addon">
                          <i class="ri-eye-fill align-middle"></i>
                        </button>
                        <div class="invalid-feedback">
                          <span></span>
                        </div>
                      </div>
                    </div>

                 

                    <div class="mt-4">
                      <button class="btn btn-success w-100" type="submit" @click="loginsession" :disabled="tryingToLogIn">
                      <div class="spinner-border spinner-border-sm" role="status" v-if="tryingToLogIn"> 
  <span class="sr-only">Loading...</span>
</div>
                        Sign In
                      </button>
                    </div>

                   
                  </form>
                </div>
              </div>
              <!-- end card body -->
            </div>
            <!-- end card -->

            <div class="mt-4 text-center" v-if="auth == false">
              <p class="mb-0">
                Don't have an account ?
                <router-link to="/register" class="fw-semibold text-primary
                  text-decoration-underline">
                  Signup
                </router-link>
              </p>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">
                &copy; {{ new Date().getFullYear() }} Gopay. Crafted with
                <i class="mdi mdi-heart text-danger"></i> by Go Softwares
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>